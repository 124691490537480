<template>
  <div>
    <div class="page-title">
      <h3>
        Редактирование заявки
        <a
          v-if="has_report"
          v-tooltip="'Открыть отчет'"
          class="btn-floating pulse btn-large waves-effect waves-light"
          :class="{'blue darken-4': report_completed, 'red darken-4': !report_completed}"
          :href="'/tasks/' + id + '/report'">
          <i class="material-icons">assignment</i>
        </a>
      </h3>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Вернуться'"
           @click="$router.push('/tasks')">
          <i class="material-icons">arrow_back</i>
        </a>
      </span>
    </div>

    <Loader v-if="loading"/>

    <form class="form" @submit.prevent="submitHandler">
      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="id" type="text" v-model="id" disabled>
            <label for="id">ID</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="engineer" type="text" v-model="engineerName" disabled>
            <label for="engineer">Инженер</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <i class="material-icons prefix cursor_pointer" v-tooltip="'Открыть оригинальную заявку'" v-if="type === 'ТЗ'" @click="openX5ExternalTask">open_in_new</i>
            <input id="external_id" type="text" v-model="external_id" v-bind:disabled="type === 'ТЗ' || !canEdit">
            <label for="external_id">Внешний ID</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="status" type="text" v-bind:value="status | status()" disabled>
            <label for="status">Статус</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <i class="material-icons prefix cursor_pointer" v-tooltip="'Открыть в новом окне'" v-if="parent_name" @click="openParentTask">open_in_new</i>
            <input id="parent_name" type="text" v-model="parent_name" disabled>
            <label for="parent_name">Родительская задача</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="external_status" type="text" v-model="external_status" disabled>
            <label for="external_status">Внешний статус</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="object_name" type="text" v-model="object_name" disabled>
            <label for="object_name">Объект</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="planned_end_time" type="text" v-model.lazy="planned_end_time" class="datepicker" autocomplete="off" v-datePicker="planned_end_time" :class="{invalid: $v.planned_end_time.$error}" v-bind:disabled="!canEdit">
            <label for="planned_end_time">План. дата завершения</label>
            <small class="helper-text invalid" v-if="$v.planned_end_time.$error">Не указана запланированная дата выполнения</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <section v-if="team_id || !canEdit">
              <input id="department_id" type="text" v-model="department_name" disabled>
              <label for="department_id">Отдел</label>
            </section>
            <section v-else>
              <select id="department_id_select" ref="department_select" v-model="department_id" @change="changeDepartment">
                <option value="" selected>Нет</option>
                <option v-for="r in department_list" :key="r.id" :value="r.id">{{r.name}}</option>
              </select>
              <label for="department_id_select">Отдел</label>
            </section>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="external_planned_end_time" type="text" v-model="external_planned_end_time" disabled>
            <label for="external_planned_end_time">План. время внешнее</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <select id="team_id" ref="team_select" v-model="team_id" @change="changeTeam" v-bind:disabled="!canEdit">
              <option value="" selected>Нет</option>
              <option v-for="r in team_list.teams" :key="r.id" :value="r.id">{{r.name}}</option>
            </select>
            <label for="team_id">Бригада</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="external_created_at" type="text" v-model="external_created_at" disabled>
            <label for="external_created_at">Время создания (внешнее)</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <select id="project_id" ref="project_select" v-model="project_id" v-bind:disabled="!canEdit">
              <option value="" selected>Нет</option>
              <option v-for="r in project_list" :key="r.id" :value="r.id">{{r.name}}</option>
            </select>
            <label for="project_id">Проект</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="external_updated_at" type="text" v-model="external_updated_at" disabled>
            <label for="external_updated_at">Время обновления (внешнее)</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <textarea id="title" class="materialize-textarea" v-model="title" :class="{invalid: $v.title.$error}" v-bind:disabled="type === 'ТЗ' || !canEdit"></textarea>
            <label for="title">Название</label>
            <small class="helper-text invalid" v-if="$v.title.$error">Не указано название</small>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="started_time" type="text" v-model="started_time" disabled>
            <label for="started_time">Время начала работы</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <textarea id="description" class="materialize-textarea" v-model="description" v-bind:disabled="type === 'ТЗ' || !canEdit"></textarea>
            <label for="description">Описание</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="completed_time" type="text" v-model="completed_time" disabled>
            <label for="completed_time">Время отправки отчета</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <textarea id="note" class="materialize-textarea" v-model="note"></textarea>
            <label for="note">Примечание</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="closed_time" type="text" v-model="closed_time" disabled>
            <label for="closed_time">Время закрытия</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <textarea id="categories" class="materialize-textarea" v-model="categories" disabled></textarea>
            <label for="categories">Категории</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="created_at" type="text" v-model="created_at" disabled>
            <label for="created_at">Время создания</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s6">
          <div class="input-field">
            <input id="type" type="text" v-model="type" disabled>
            <label for="type">Тип</label>
          </div>
        </div>
        <div class="col s6">
          <div class="input-field">
            <input id="updated_at" type="text" v-model="updated_at" disabled>
            <label for="updated_at">Время изменения</label>
          </div>
        </div>
      </div>

      <div class="row" v-if="status !== 'closed'">
        <div class="col s5" v-if="status !== 'verifying'">
          <button class="btn waves-effect waves-light blue darken-4" type="submit">
            Сохранить
            <i class="material-icons right">send</i>
          </button>
        </div>
        <div class="col s5" v-else-if="status === 'verifying'">
          <a class="btn waves-effect waves-light green darken-4" @click="close">Принять акт</a>
        </div>
        <div class="col s4" v-if="canEdit">
          <a class="btn waves-effect waves-light red darken-4" @click="cancel">Отменить</a>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import {required} from 'vuelidate/lib/validators'
  import dateFilter from '../../filters/date.filter'
  import convertDateString from '../../utils/date'

  export default {
    metaInfo() {
      return {
        title: 'Редактирование заявки'
      }
    },
    data: () => ({
      isAdmin: false,
      loading: true,
      task: {},
      status_select: null,
      department_select: null,
      team_select: null,
      project_select: null,
      parent_list: [],
      department_list: [],
      team_list: [],
      project_list: [],
      id: null,
      external_id: '',
      parent_id: null,
      parent_name: '',
      object_id: null,
      object_name: '',
      department_id: null,
      department_name: '',
      team_id: null,
      original_team_id: null,
      project_id: null,
      title: '',
      description: '',
      note: '',
      categories: '',
      type: '',
      status: '',
      external_status: '',
      planned_end_time: null,
      external_planned_end_time: null,
      external_created_at: null,
      external_updated_at: null,
      started_time: null,
      completed_time: null,
      closed_time: null,
      created_at: null,
      updated_at: null,
      engineer: null,
      engineerName: '',
      has_report: false,
      report_completed: false,
    }),
    validations: {
      title: {required},
      planned_end_time: {required},
    },
    async mounted() {
      this.isAdmin = await this.$store.dispatch('profile/isAdmin')
      const task = await this.$store.dispatch('fetchTaskById', this.$route.params.id)

      this.task = task
      this.id = task.id
      this.external_id = task.external_id || ''
      this.parent_id = task.parent ? task.parent.id : ''
      this.parent_name = task.parent ? task.parent.id + '. ' + task.parent.title : ''
      this.object_id = task.object ? task.object.id : ''
      this.object_name = task.object ? task.object.id + '(' + task.object.external_id + '). ' + task.object.locality : ''
      this.department_id = task.department ? task.department.id : ''
      this.department_name = task.department ? task.department.name : ''
      this.team_id = task.team ? task.team.id : ''
      this.original_team_id = this.team_id
      this.project_id = task.project ? task.project.id : ''
      this.title = task.title || ''
      this.description = task.description || ''
      this.note = task.note || ''
      this.type = task.type || ''
      this.status = task.status || ''
      this.external_status = task.external_status || ''
      this.planned_end_time = task.planned_end_time ? dateFilter(task.planned_end_time.seconds * 1000, 'date') : null
      this.external_planned_end_time = task.external_planned_end_time ? dateFilter(task.external_planned_end_time.seconds * 1000, 'datetime') : null
      this.external_created_at = task.external_created_at ? dateFilter(task.external_created_at.seconds * 1000, 'datetime') : null
      this.external_updated_at = task.external_updated_at && task.external_updated_at.seconds > 1000000 ? dateFilter(task.external_updated_at.seconds * 1000, 'datetime') : 'Не изменялась'
      this.started_time = task.started_time? dateFilter(task.started_time.seconds * 1000, 'datetime') : null
      this.completed_time = task.done_time ? dateFilter(task.done_time.seconds * 1000, 'datetime') : null
      this.closed_time = task.closed_time ? dateFilter(task.closed_time.seconds * 1000, 'datetime') : null
      this.created_at = dateFilter(task.created_at.seconds * 1000, 'datetime')
      this.updated_at = dateFilter(task.updated_at.seconds * 1000, 'datetime')
      this.engineer = task.engineer
      this.engineerName = task.engineer ? task.engineer.last_name + ' ' + task.engineer.first_name : ''
      this.has_report = task.has_report || false
      this.report_completed = task.report_completed || false

      if (task.categories && task.categories.length > 0) {
        this.categories = task.categories.join(', ')
      }

      const departments = await this.$store.dispatch('fetchDepartments')
      this.department_list = departments.filter(event => event.id > 1)

      if (this.isAdmin) {
        this.team_list = await this.$store.dispatch('fetchAllTeams')
      } else {
        this.team_list = await this.$store.dispatch('fetchTeams', {})
      }

      this.project_list = await this.$store.dispatch('fetchProjects')

      this.loading = false

      setTimeout(() => {
        this.status_select = M.FormSelect.init(this.$refs.status_select)
        this.department_select = M.FormSelect.init(this.$refs.department_select)
        this.team_select = M.FormSelect.init(this.$refs.team_select)
        this.project_select = M.FormSelect.init(this.$refs.project_select)
        M.updateTextFields()
        M.textareaAutoResize(document.getElementById('title'))
        M.textareaAutoResize(document.getElementById('description'))
        M.textareaAutoResize(document.getElementById('note'))
        M.textareaAutoResize(document.getElementById('categories'))
      })
    },
    computed: {
      canEdit: function () {
        return ['waiting', 'not_started'].includes(this.status)
      },
    },
    methods: {
      async submitHandler() {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return
        }

        const task = {
          id: this.id,
          external_id: this.external_id,
          parent: this.parent_id ? {"id": this.parent_id} : null,
          department: {"id": this.department_id},
          engineer: this.engineer.hasOwnProperty('id') ? {"id": this.engineer.id} : null,
          team: this.team_id ? {"id": this.team_id} : null,
          title: this.title,
          description: this.description,
          note: this.note,
          planned_end_time: this.planned_end_time ? {seconds: convertDateString(this.planned_end_time + ' 23:59:59').getTime() / 1000} : null,
        }

        if (this.original_team_id !== this.team_id) {
          task.engineer = {"id": await this.$store.dispatch('profile/getUid')}
        }

        await this.$store.dispatch('updateTask', task)
        await this.$router.push('/tasks')
      },
      changeTeam() {
        if (this.department_select && this.department_select.destroy) {
          this.department_select.destroy()
        }

        setTimeout(() => {
          this.department_select = M.FormSelect.init(this.$refs.department_select)
        })
      },
      async changeDepartment() {
        this.team_list = await this.$store.dispatch('fetchTeams', {departmentId: this.department_id})

        if (this.team_select && this.team_select.destroy) {
          this.team_select.destroy()
        }

        setTimeout(() => {
          this.team_select = M.FormSelect.init(this.$refs.team_select)
        })
      },
      async cancel() {
        if (this.note === '') {
          M.toast({ html: 'Заполните примечание' })
          return
        }

        const task = {
          task_id: this.id,
          note: this.note,
        }

        await this.$store.dispatch('cancelTask', task)
        await this.$router.push('/tasks')
      },
      async close() {
        await this.$store.dispatch('closeTask', this.id)
        await this.$router.push('/tasks')
      },
      openParentTask() {
        window.open('/tasks/' + this.parent_id)
      },
      openX5ExternalTask() {
        window.open('https://expl.x5.ru/#task/' + this.external_id)
      },
    },
    destroyed() {
      if (this.status_select && this.status_select.destroy) {
        this.status_select.destroy()
      }
      if (this.department_select && this.department_select.destroy) {
        this.department_select.destroy()
      }
      if (this.team_select && this.team_select.destroy) {
        this.team_select.destroy()
      }
      if (this.project_select && this.project_select.destroy) {
        this.project_select.destroy()
      }
    }
  }
</script>

<style scoped>
  .cursor_pointer {
    cursor: pointer;
  }
</style>
